import React, { useContext, useState } from "react"
import styled from "@emotion/styled"

import Button from "../ui/Button"
import Spacing from "../ui/Spacing"
import CartItem, {
  CartItemContainer,
  CartItemDetails,
  CartItemFooter,
} from "./CartItem"
import { navigate } from "gatsby"
import StoreContext from "../../context/StoreContext"
import getDisplayItems from "../../utils/get-display-items"

const Row = styled.div`
  ${Spacing}
  display: flex;
  justify-content: ${props => (props.emptyCart ? "center" : "space-between")};

  button {
    text-align: center;
    font-size: 16px;
  }

  ${props => props.theme.breakpointsLegacy.tablet} {
    padding-top: 40px;
    padding-bottom: 40px;
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const Modal = styled.div`
  display: ${props => (props.show ? "none" : "none")};
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);

  h3 {
    text-align: center;
    font-size: 16px;
  }

  h4 {
    text-align: center;
    font-weight: normal;
    margin-bottom: 40px;
  }

  ${props => props.theme.breakpointsLegacy.tablet} {
    h3 {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  ${props => props.theme.breakpointsLegacy.desktop} {
    h3 {
      font-size: 22px;
      margin-bottom: 20px;
    }
  }
`

const ModalContent = styled.div`
  padding: 20px;
  width: 90%;
  max-height: 90vh;
  overflow-y: scroll;
  background-color: white;

  ${props => props.theme.breakpointsLegacy.tablet} {
    padding: 40px;
    width: 60%;
  }

  ${props => props.theme.breakpointsLegacy.desktop} {
    padding-left: 65px;
    padding-right: 65px;
    padding-bottom: 40px;
    padding-top: 40px;
    max-width: 600px;
  }
`

const Content = styled.div``

const OutOfStockItem = ({ item }) => {
  return (
    <CartItemContainer isCheckout={false}>
      <CartItemDetails isCheckout={false}>
        <img src={item.thumbnail} alt={item.title} />
        <div className="info">
          <div className="item-details-name">{item.title}</div>
          <div className="item-details-variant">
            {!item.is_giftcard && item.description}
          </div>
        </div>
      </CartItemDetails>
    </CartItemContainer>
  )
}

const OutOfStockPopup = ({ lineItems, hidePopup }) => {
  // const items = getDisplayItems({ items: lineItems })

  return (
    <Modal>
      <ModalContent>
        <Content>
          <h3>Some of your items have sold out</h3>
          <h4>We've updated your cart accordingly</h4>
          {/* {items.map((item, i) => (
            <OutOfStockItem key={i} item={item} />
          ))} */}
          <Row pt={"40px"}>
            <Button
              secondary
              outline
              style={{ paddingRight: "6px" }}
              onClick={() => {
                navigate(`/l/all-products`)
                hidePopup()
              }}
            >
              Shop other items
            </Button>
            <Button style={{ paddingRight: "6px" }} onClick={() => hidePopup()}>
              Continue checkout
            </Button>
          </Row>
        </Content>
      </ModalContent>
    </Modal>
  )
}

export default OutOfStockPopup
