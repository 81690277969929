import React from "react"
import { Trans, useTranslation } from "react-i18next"

import { FormRow, SavedStep, ValidationNotification } from "../checkout-step"
import Input from "../../new-ui/input-field"
import Checkbox from "../../new-ui/checkbox"

const EmailSection = ({
  formik,
  loggedIn,
  loginFailed,
  onNewsletterOptIn,
  newsletterOptIn,
  hasAccount,
}) => {
  const { t } = useTranslation()

  return (
    <div>
      {!loggedIn && hasAccount && <div>{t("existing_account")}</div>}
      {loginFailed && (
        <ValidationNotification>
          {t("unrecognized_pass")}
        </ValidationNotification>
      )}
      <FormRow marginBottom="10px">
        <Input
          maxLength="50"
          placeholder={t("email")}
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && formik.errors.email}
        />
      </FormRow>
      {formik.values.email && (
        <FormRow marginBottom="1rem">
          <Checkbox
            onClick={onNewsletterOptIn}
            value={newsletterOptIn}
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              ".base-checker": {
                display: "block",
                width: "18px",
                height: "18px",
                marginRight: "6px",
              },
              ".label": {
                width: "80%",
                display: "block",
                flexWrap: "wrap",
              },
            }}
            margin=".5rem 0"
            label={
              <Trans as="label" i18nKey="newsletter_notice">
                Yes, sign me up for Tekla Fabrics news & other digital
                communications. I confirm that I am above 15 years old and
                reviewed <a href="/privacy-policy">Privacy Policy</a>. You can
                unsubscribe at any time by clicking the link at the bottom of a
                newsletter.
              </Trans>
            }
          />
        </FormRow>
      )}
    </div>
  )
}

export default EmailSection
