import React from "react"

import { CheckoutStep } from "../checkout-step"
import KlarnaCheckout from "./klarna-checkout"
import { useMedusaCheckout } from "../../medusa-checkout-builder"

const CheckoutButtons = ({ active, cart }) => {
  const buttonProviders = ["klarna"]

  const { setPaymentSession, paymentSession } = useMedusaCheckout()

  const handleDismiss = () => {
    setPaymentSession("stripe")
  }

  const sessions = cart.payment_sessions

  if (!sessions.find(s => buttonProviders.includes(s.provider_id))) {
    return null
  }

  return (
    <CheckoutStep saved={!active}>
      {sessions.map(s => {
        switch (s.provider_id) {
          case "klarna":
            return (
              <KlarnaCheckout
                cart={cart}
                session={s}
                active={paymentSession?.provider_id === "klarna"}
                onDismiss={handleDismiss}
                onCheckout={() => setPaymentSession("klarna")}
              />
            )

          default:
            return null
        }
      })}
    </CheckoutStep>
  )
}

export default CheckoutButtons
