import React from "react"
import styled from "@emotion/styled"
import { useTranslation } from "react-i18next"

import Typography from "../ui/Typography"

const Ticker = styled.div`
  cursor: pointer;
`

const QuantityTickerContainer = styled.div`
  ${Typography.Base};
  display: flex;

  justify-content: space-between;
  align-items: center;
  border-radius: 2px;

  transition: transform 200ms;
  transform-origin: left;
  transform: scaleX(${props => (props.visible ? 1 : 0)});

  width: 100%;

  ${props => props.theme.breakpointsLegacy.tablet} {
    width: max-content;
  }

  ${props =>
    props.small
      ? `
    height: 1.5rem;
    ${Ticker} {
      flex-grow: 1;
      padding-top: 2px;
      padding-left: 10px;
      padding-right: 10px;
      &:first-of-type {
        margin-left: -10px;
      }
      &:last-of-type {
        margin-right: -10px;
      }
      font-size: 0.75rem;
    }
  `
      : `
    height: 100%;
    border: 1px solid ${props.theme.colors.darkest};
    ${Ticker} {
      &:first-of-type {
        padding-left: 1.4rem;
        padding-right: 10px;
      }
      &:last-of-type {
        padding-left: 10px;
        padding-right: 1.4rem;
      }
      padding-top: 2px;
      font-size: 0.75rem;
    }
  `}
`

const QuantityDisplay = styled.div`
  text-align: center;
  width: 18px;
`

const Container = styled.div`
  ${props => !props.inline && `width: 100%`};
  ${props => !props.inline && `height: 28px`};
  ${props => !props.inline && `margin-bottom: 10px;`};
  ${props =>
    props.inline && (props.visible ? "max-width: 300px" : "max-width: 0")};
  margin-right: ${props => (props.inline && props.visible ? "1rem" : 0)};

  ${props => props.theme.breakpointsLegacy.tablet} {
    height: unset;
    margin-bottom: 0;
    width: unset !important;
    transition: max-width 300ms;
    max-width: ${props => (props.visible ? "300px" : 0)};
    margin-right: ${props => (props.visible ? "1rem" : 0)};
  }
`

const QuantityTicker = ({
  small,
  showSoldOut,
  quantity,
  onQuantityChanged,
  visible,
  inline,
}) => {
  const { t } = useTranslation()
  const handleDecrementQuantity = () => {
    if (onQuantityChanged) {
      onQuantityChanged(quantity - 1)
    }
  }

  const handleIncrementQuantity = () => {
    if (onQuantityChanged) {
      onQuantityChanged(quantity + 1)
    }
  }

  return (
    <Container visible={visible} inline={inline}>
      {showSoldOut ? (
        <QuantityTickerContainer small={small}>
          <div />
          <QuantityDisplay>
            <i>{t("sold_out")}</i>
          </QuantityDisplay>
          <div />
        </QuantityTickerContainer>
      ) : (
        <QuantityTickerContainer visible={visible} small={small}>
          <Ticker onClick={handleDecrementQuantity}>
            <i className="fal fa-minus" />
          </Ticker>
          <QuantityDisplay>{quantity || 0}</QuantityDisplay>
          <Ticker onClick={handleIncrementQuantity}>
            <i className="fal fa-plus" />
          </Ticker>
        </QuantityTickerContainer>
      )}
    </Container>
  )
}

export default QuantityTicker
