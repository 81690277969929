import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import { useTranslation } from "react-i18next"
import { Flex, Box } from "theme-ui"
import { PayPalScriptProvider } from "@paypal/react-paypal-js"

import CheckoutLayout from "./checkout-layout"
import ShippingStep from "./shipping-step"
import DeliveryStep from "./delivery-step"
import CartSummary from "./cart-summary"

import LeftArrow from "../../assets/svg/arrow-left.svg"
import DownArrow from "../../assets/svg/down-arrow-small.svg"

import BreadCrumbs from "../new-ui/bread-crumbs"
import LoadingSpinner from "../ui/LoadingSpinner"
import Button from "../ui/Button"
import checkInventory from "../../utils/check-inventory"
import Logo from "../../assets/logo.png"
import OutOfStockPopup from "../ui/OutOfStock"
import { formatCartTotal } from "../../utils/prices"

import "../../fonts/styles.css"

import { useMedusaCheckout } from "../medusa-checkout-builder"

import {
  Header,
  ControlsContainer,
  Controls,
  Summary,
  EmptyCart,
  CheckoutContainer,
} from "./elements"

import CheckoutButtons from "./checkout-buttons"
import PaymentStep from "./payment-step"
import { useTranslationContext } from "../../context/TranslationContext"
import { useAccountContext } from "../../context/AccountContext"

const PAYPAL_CLIENT_ID =
  process.env.GATSBY_PAYPAL_CLIENT_ID ||
  "AZkdvdsRMCeX27ZlDkHvqpYpekGtH6T2flGXqIfpV2MH6NIiLcbA9AnCCv8--nFT7cF59E31XijjFLvN"
const PAYPAL_INTENT = process.env.PAYPAL_INTENT || "authorize"

const Checkout = () => {
  const { translationContext } = useTranslationContext()

  const { t } = useTranslation()

  const [gcError, setGcError] = useState(null)
  const [ccError, setCcError] = useState(null)
  const [paymentFailed, setPaymentFailed] = useState(null)
  const [showCart, toggleCart] = useState(false)
  const [currentStep, setStep] = useState("shipping")
  const [cartInventoryCorrection, setCartInventoryCorrection] = useState(false)
  const [settingShipping, setSettingShipping] = useState(false)

  const {
    loggedIn,
    firstName,
    lastName,
    email,
    savedAddresses,
  } = useAccountContext()

  const {
    isLoading,
    cart,
    errors,
    hasEmail,
    hasShippingAddress,
    hasShipping,
    update,
    addShippingMethod,
    getRegions,
    removeDiscount,
    completeCart,
    removeLineItem,
    updateLineItem,
    processingDetails,
    processingShipping,
  } = useMedusaCheckout()

  useEffect(() => {
    const expressLogin = async () => {
      if (loggedIn && cart.id) {
        const address = savedAddresses[0]
        const userInfo = {
          address_1: address.address_1,
          address_2: address.address_2,
          first_name: firstName,
          last_name: lastName,
          city: address?.city,
          postal_code: address?.postal_code,
          province: address?.province,
          country_code: address?.country_code,
          phone: address?.phone,
        }

        getRegions()
          .then(({ data }) => data.regions)
          .then((regions) => {
            let result
            regions.forEach((region) => {
              const country = region.countries.find(
                (country) =>
                  country.iso_2.toLowerCase() ===
                  userInfo.country_code.toLowerCase()
              )
              if (country) {
                result = region
              }
            })
            return result
          })
          .then((region) => {
            update({
              email,
              region_id: region.id,
              shipping_address: userInfo,
              billing_address: userInfo,
            })
          })
      }
    }

    expressLogin()
  }, [loggedIn])

  useEffect(() => {
    switch (true) {
      case hasEmail && hasShippingAddress && hasShipping:
        setStep("payment")
        break
      case hasEmail && hasShippingAddress:
        setStep("delivery")
        break
      default:
        setStep("shipping")
        break
    }
  }, [hasEmail, hasShipping, hasShippingAddress, cart.shipping_address])

  const showSpinner = isLoading || !cart.payment_sessions

  /**
   * Sets the current step to step if the necessary conditions are satisfied.
   */
  const handleEditStep = (step) => {
    // Let fall through if conditions are satisfied
    switch (step) {
      case "payment":
        if (!(hasEmail && hasShippingAddress && hasShipping)) {
          return
        }
      // ignore-no-fallthrough
      case "delivery":
        if (!(hasEmail && hasShippingAddress)) {
          return
        }
      // ignore-no-fallthrough
      default:
        setStep(step)
    }
  }

  /**
   * Updates that cart with the provided address and email
   */
  const handleShippingSubmit = async (address, email) => {
    const result = await ensureInventory()

    if (result.didUpdate) {
      setCartInventoryCorrection(true)
      return
    }

    if (window?.analytics) {
      window.analytics.identify({
        firstName: address.first_name,
        lastName: address.last_name,
        address: {
          city: address.city,
          postalCode: address.postal_code,
          country: address.country_code,
          street: address.address_1,
        },
        email,
      })
    }

    // Enforce state in US and Canada for shipping provider
    if (address.country_code === ("us" || "ca") && !address.province) {
      return
    }

    if (
      address.country_code !== cart.shipping_address.country_code.toLowerCase()
    ) {
      getRegions()
        .then(({ data }) => data.regions)
        .then((regions) => {
          let result
          regions.forEach((region) => {
            const country = region.countries.find(
              (country) =>
                country.iso_2.toLowerCase() ===
                address.country_code.toLowerCase()
            )
            if (country) {
              result = region
            }
          })
          return result
        })
        .then((region) => {
          update({
            email,
            region_id: region.id,
            shipping_address: address,
            billing_address: address,
          })
        })
    } else {
      await update({
        email,
        shipping_address: address,
        billing_address: address,
      })
    }
  }

  /**
   * Updates the cart's shipping methods.
   */
  const handleDeliverySubmit = async (options) => {
    setSettingShipping(true)
    const result = await ensureInventory()

    if (result.didUpdate) {
      setCartInventoryCorrection(true)
      return
    }

    const selected = Object.keys(options).map((k) => options[k])

    const methods = await Promise.all(
      selected.map((option) => {
        return addShippingMethod({
          option_id: option.id,
          data: option.data,
        })
      })
    )

    setSettingShipping(false)
    return methods
  }

  const handleCountryChange = async (countryCode) => {
    getRegions()
      .then(({ data }) => data.regions)
      .then((regions) => {
        regions.forEach(async (region) => {
          const country = region.countries.find(
            (country) =>
              country.iso_2.toLowerCase() === countryCode.toLowerCase()
          )

          if (country) {
            await setRegion({ regionId: region.id, countryCode: country.iso_2 })
            return
          }
        })
      })
      .catch((err) => console.log(err))
  }

  /**
   * Deletes all items in a cart with the given bundle id
   */
  const deleteBundleItems = async (bundleId) => {
    return Promise.all(
      cart.items
        .filter((item) => item.metadata?.bundle_id === bundleId)
        .map((item) => {
          removeLineItem(item.id)
        })
    )
  }

  /**
   * Ensure inventory
   */
  const ensureInventory = async () => {
    const result = await checkInventory(cart)

    if (result.didUpdate) {
      for (const b of result.bundlesToDelete) {
        await deleteBundleItems(b.id)
      }

      for (const i of result.itemsToDelete) {
        await removeLineItem(i.id)
      }

      for (const i of result.itemsToReduce) {
        await updateLineItem(i.id, i.quantity)
      }
    }

    return result
  }

  /**
   * If payment is completed by the underlying component in payment state
   * go straight to the payment site
   */
  const handlePaymentCompleted = async () => {
    navigate("/checkout/payment")
  }

  /**
   * Try to authorize payment
   */
  const handleAuthorize = async () => {
    return completeCart()
  }

  /**
   * Adds gift card to cart.
   */
  const handleAddGiftCard = async (gcCode) => {
    setGcError(false)
    const newCards = cart.gift_cards.map(({ code }) => ({
      code,
    }))

    return update({
      gift_cards: [...newCards, { code: gcCode }],
    }).catch((err) => {
      switch (err.response.status) {
        case 400:
          setGcError(err.response.data.message)
          break
        case 404:
          setGcError(true)
          break
        default:
          break
      }
      throw err
    })
  }

  /**
   * Removes a gift card
   */
  const handleRemoveGiftCard = async (gcCode) => {
    const newCards = cart.gift_cards.filter(({ code }) => code !== gcCode)

    return update({
      gift_cards: newCards,
    })
  }

  /**
   * Adds discount code to cart
   */
  const handleAddCouponCode = (couponCode) => {
    setCcError(false)
    return update({
      discounts: [{ code: couponCode }],
    }).catch((err) => {
      if (err.response.status >= 400 && err.response.status < 500) {
        setCcError(true)
      }
      throw err
    })
  }

  /**
   * Remove discount code from cart
   */
  const handleRemoveCouponCode = (couponCode) => {
    return removeDiscount(couponCode)
  }

  const handleStepRender = () => {
    switch (currentStep) {
      case "shipping":
        return (
          <ShippingStep
            canEditCountry
            cart={cart}
            cartAddress={cart.shipping_address}
            validationErrors={errors}
            isProcessing={processingDetails}
            onEdit={() => handleEditStep("shipping")}
            onSubmit={handleShippingSubmit}
            handleCountryChange={handleCountryChange}
          />
        )
      case "delivery":
        return (
          <DeliveryStep
            index={2}
            cart={cart}
            shouldDisable={settingShipping}
            isProcessing={processingShipping}
            savedMethods={cart.shipping_methods}
            active={currentStep === "delivery"}
            onEditAddress={() => handleEditStep("shipping")}
            onEdit={() => handleEditStep("delivery")}
            onSubmit={handleDeliverySubmit}
          />
        )
      case "payment":
        return (
          <PaymentStep
            index={3}
            paymentFailed={paymentFailed}
            onEditAddress={() => handleEditStep("shipping")}
            onEditDelivery={() => handleEditStep("delivery")}
            onAuthorize={handleAuthorize}
            onPaymentCompleted={handlePaymentCompleted}
            setCartInventoryCorrection={setCartInventoryCorrection}
          />
        )
      default:
        return null
    }
  }

  const home = translationContext.locale === "en-US" ? "/" : "/de/"

  return (
    <CheckoutLayout showNavbar={cart.items.length === 0}>
      {showSpinner && <LoadingSpinner bg={"#f7f6f5ab"} />}
      {cartInventoryCorrection && cart.items.length === 0 && (
        <EmptyCart>
          <h1>{t("your_items_sold_out")}</h1>
          <Link to={home}>
            <Button>{t("continue_shopping")}</Button>
          </Link>
        </EmptyCart>
      )}
      {cartInventoryCorrection && cart.items.length > 0 && (
        <OutOfStockPopup
          lineItems={[]}
          hidePopup={() => setCartInventoryCorrection(false)}
        />
      )}

      {cart.items.length === 0 && !cartInventoryCorrection ? (
        <EmptyCart>
          <h1>{t("cart_empty")}</h1>
          <Link to={home}>
            <Button>{t("continue_shopping")}</Button>
          </Link>
        </EmptyCart>
      ) : (
        cart.id &&
        !cartInventoryCorrection && (
          <PayPalScriptProvider
            options={{
              currency: cart.currency_code.toUpperCase(),
              "disable-funding": "card",
              intent: PAYPAL_INTENT,
              "client-id": PAYPAL_CLIENT_ID,
            }}
          >
            <CheckoutContainer>
              <ControlsContainer>
                <Controls>
                  <Header>
                    <Flex
                      py={"16px"}
                      sx={{
                        alignItems: "center",
                        justifyContent: [
                          "space-between",
                          "space-between",
                          "flex-start",
                        ],
                        top: 0,
                        position: "sticky",
                        a: { textDecoration: "none" },
                      }}
                    >
                      <Box sx={{ height: "18px" }}>
                        <Link to={home}>
                          <img alt="Tekla Logo" src={Logo} />
                        </Link>
                      </Box>
                      <Flex sx={{ justifyContent: "space-between" }}>
                        <Link to={home}>
                          <Flex
                            ml={"2rem"}
                            sx={{
                              alignItems: "center",
                            }}
                          >
                            <Flex sx={{ alignItems: "center" }} mr={"8px"}>
                              <LeftArrow />
                            </Flex>
                            {t("back_to_shop")}
                          </Flex>
                        </Link>
                      </Flex>
                    </Flex>
                    <Flex
                      mt={"2rem"}
                      sx={{
                        display: ["flex", "flex", "none"],
                        flexDirection: "column",
                      }}
                    >
                      <Flex
                        sx={{
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        onClick={() => toggleCart(!showCart)}
                      >
                        <Flex sx={{ alignItems: "center" }}>
                          <Box mr={1}>{t("view_order_summary")}</Box>{" "}
                          <DownArrow />
                        </Flex>
                        <Box sx={{ fontWeight: 600 }}>
                          {formatCartTotal(cart)}
                        </Box>
                      </Flex>
                      {showCart && (
                        <CartSummary
                          giftCardError={gcError}
                          couponCodeError={ccError}
                          onRemoveGiftCard={handleRemoveGiftCard}
                          onAddGiftCard={handleAddGiftCard}
                          onAddCouponCode={handleAddCouponCode}
                          onRemoveCouponCode={handleRemoveCouponCode}
                          onUpdateQuantity={updateLineItem}
                          onRemoveLine={removeLineItem}
                          disableEdit={true}
                          cart={cart}
                        />
                      )}
                    </Flex>
                  </Header>
                  <Flex
                    mt={"20px"}
                    pt={["1rem", "1rem", "1rem"]}
                    p={["16px", "1rem"]}
                    pl={["", "120px"]}
                    sx={{
                      bg: ["white", "white", "transparent"],
                      flexDirection: "column",
                    }}
                  >
                    <BreadCrumbs
                      onClick={(c) => handleEditStep(c.value)}
                      value={currentStep}
                      crumbs={[
                        { value: "shipping", label: t("information") },
                        { value: "delivery", label: t("delivery") },
                        { value: "payment", label: t("payment") },
                      ]}
                    />
                    <CheckoutButtons active={true} cart={cart} />
                    {handleStepRender()}
                  </Flex>
                </Controls>
              </ControlsContainer>
              <Summary
                sx={{
                  display: [
                    "none !important",
                    "none !important",
                    "flex !important",
                  ],
                }}
              >
                <CartSummary
                  giftCardError={gcError}
                  couponCodeError={ccError}
                  onRemoveGiftCard={handleRemoveGiftCard}
                  onAddGiftCard={handleAddGiftCard}
                  onAddCouponCode={handleAddCouponCode}
                  onRemoveCouponCode={handleRemoveCouponCode}
                  cart={cart}
                  onUpdateQuantity={updateLineItem}
                  onRemoveLine={removeLineItem}
                  disableEdit={true}
                />
              </Summary>
            </CheckoutContainer>
          </PayPalScriptProvider>
        )
      )}
    </CheckoutLayout>
  )
}

export default Checkout
