import React from "react"
import styled from "@emotion/styled"
import mq from "../../utils/mq"
import Typography from "./Typography"

const Container = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 134215;
`

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000088;
`

const Header = styled.div`
  ${Typography.Large}
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
`

const Dismiss = styled.div`
  cursor: pointer;
`

const Body = styled.div`
  background-color: white;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  ${mq({
    width: ["95%", "90%", "80%"],
  })}
  z-index: 1342153;
  overflow: scroll;
  overflow-scrolling: touch;
`

const Content = styled.div``

const Modal = ({ title, children, onDismiss }) => {
  const handleDismiss = () => {
    if (onDismiss) {
      onDismiss()
    }
  }

  return (
    <Container>
      <Background />
      <Body>
        <Header>
          <div>{title}</div>
          <Dismiss onClick={handleDismiss}>
            <i className="fal fa-times" />
          </Dismiss>
        </Header>
        <Content>{children}</Content>
      </Body>
    </Container>
  )
}

export default Modal
