import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"

import Checkout from "../../components/checkout"
import SEO from "../../components/seo"
import { MedusaCheckoutProvider } from "../../components/medusa-checkout-builder"

const CheckoutPage = () => {
  const [cartId, setCartId] = useState(null)

  useEffect(() => {
    const [htmlTag] = document.getElementsByTagName("html")
    const [bodyTag] = document.getElementsByTagName("body")

    htmlTag.style.removeProperty("overflow")
    bodyTag.style.removeProperty("overflow")

    const cachedCart = localStorage.getItem("medusa::cache")
    const cart = JSON.parse(cachedCart)

    if (!cart) {
      navigate("/")
      return
    } else {
      setCartId(cart.id)
    }
  }, [])

  return (
    <MedusaCheckoutProvider cartId={cartId}>
      <SEO title="Checkout" />
      <Checkout />
    </MedusaCheckoutProvider>
  )
}

export default CheckoutPage
