import React from "react"
import styled from "@emotion/styled"

import QuantityTicker from "../ProductPage/QuantityTicker"
import { formatPrices } from "../../utils/prices"
import Typography from "../ui/Typography"

const ItemPrice = styled.div`
  ${props => (props.isCheckout ? Typography.Small : Typography.Base)};
  ${props => props.isCheckout && `color: ${props.theme.colors.darkest}`};
  ${props => !props.isCheckout && `float: right`};
  ${props => props.isCheckout && `color: ${props.theme.colors.darkest}`};
`

export const CartItemContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  min-height: 80px;
  max-height: 120px;

  margin-bottom: 1rem;
  &:last-of-type {
    margin-bottom: 0;
  }

  img {
    position: center;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &:not(:last-of-type) {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${props => props.theme.colors.almostWhite};
  }
`

export const CartItemDetails = styled.div`
  display: flex;
  justify-content: space-between;
  img {
    width: 72px;
    margin-right: 1rem;
  }

  .info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .item-details-name {
    ${props => (props.isCheckout ? Typography.Small : Typography.Base)}
    color: ${props => props.theme.colors.darkest};
  }

  .item-details-variant {
    ${Typography.Small};
    color: ${props => props.theme.colors.dark};
    flex-grow: 1;

    padding-top: 0.3rem;
  }
`

export const CartItemFooter = styled.div`
  ${Typography.Base};
  margin-top: 0.5rem;
  color: ${props => props.theme.colors.dark};
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

const RemoveFromCart = styled.div`
  cursor: pointer;
  float: right;
  margin-left: 1rem;
  margin-top: -4px;
`

const CartItem = ({
  item,
  cart,
  allowEditting,
  isCheckout,
  thumbnail,
  name,
  itemPrice,
  isBundle,
  onRemoveLine,
  onQuantityUpdate,
}) => {
  const price = itemPrice ? formatPrices(cart, itemPrice) : ""
  const regularPrice = itemPrice
    ? formatPrices(cart, regularPrice || itemPrice)
    : ""

  return (
    <CartItemContainer isCheckout={isCheckout}>
      <CartItemDetails isCheckout={isCheckout}>
        <img src={thumbnail} alt={name} />
        <div className="info">
          <div className="item-details-name">{name}</div>
          <div className="item-details-variant">
            {!item.is_giftcard && item.description}
          </div>
          {isCheckout && (
            <ItemPrice isCheckout={isCheckout}>
              {!isBundle && !allowEditting ? `${item.quantity} x ` : ``}
              {regularPrice !== price ? (
                <>
                  <strike>{regularPrice}</strike> {price}
                </>
              ) : (
                <>{price}</>
              )}
            </ItemPrice>
          )}
        </div>
        {allowEditting && (
          <RemoveFromCart onClick={() => onRemoveLine(item.id)}>
            &#215;
          </RemoveFromCart>
        )}
      </CartItemDetails>
      {!isCheckout && (
        <CartItemFooter>
          <div>
            {!isBundle && allowEditting && (
              <QuantityTicker
                visible
                small
                quantity={item.quantity}
                onQuantityChanged={quantity =>
                  onQuantityUpdate(item.id, quantity)
                }
              />
            )}
          </div>
          <ItemPrice isCheckout={isCheckout}>
            {!isBundle && !allowEditting ? `${item.quantity} x ` : ``}
            {regularPrice !== price ? (
              <>
                <strike>{regularPrice}</strike> {price}
              </>
            ) : (
              <>{price}</>
            )}
          </ItemPrice>
        </CartItemFooter>
      )}
    </CartItemContainer>
  )
}

CartItem.defaultProps = {
  isBundle: false,
}

export default CartItem
