import React from "react"
import styled from "@emotion/styled"
import { useTranslation } from "react-i18next"

import Modal from "../../ui/Modal"
import KlarnaLogo from "../../../assets/klarna/klarna-logo.svg"

const KlarnaCheckoutButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid black;
  width: max-content;
  margin-right: 0.5rem;
  img {
    height: 20px;
    margin-top: -2px;
    margin-left: 5px;
  }
  border-radius: 2px;
`

const KlarnaCheckout = ({ active, session, onDismiss, onCheckout }) => {
  const { t } = useTranslation()
  const handleKlarnaCheckout = async () => {
    await onCheckout()
    const checkoutContainer = document.getElementById("klarna-container")
    const scriptsTags = checkoutContainer.getElementsByTagName("script")
    // This is necessary otherwise the scripts tags are not going to be evaluated
    for (var i = 0; i < scriptsTags.length; i++) {
      const parentNode = scriptsTags[i].parentNode
      const newScriptTag = document.createElement("script")
      newScriptTag.type = "text/javascript"
      newScriptTag.text = scriptsTags[i].text
      parentNode.removeChild(scriptsTags[i])
      parentNode.appendChild(newScriptTag)
    }
  }

  return (
    <>
      <KlarnaCheckoutButton onClick={handleKlarnaCheckout}>
        {t("checkout_with")}
        <img alt="Klarna Logo" src={KlarnaLogo} />
      </KlarnaCheckoutButton>
      {active && (
        <Modal title={`${t("checkout_with")} Klarna`} onDismiss={onDismiss}>
          <div
            id="klarna-container"
            dangerouslySetInnerHTML={{
              __html: session.data.html_snippet,
            }}
          />
        </Modal>
      )}
    </>
  )
}

export default KlarnaCheckout
