import styled from "@emotion/styled"
import React from "react"
import { Flex } from "theme-ui"
import OpenArrow from "../../../assets/svg/dropdown-arrow.svg"
import Typography from "../../ui/Typography"

const Container = styled(Flex)`
  ${Typography.Base};
`

const BreadCrumbs = ({ crumbs, onClick, value, disableClicks }) => {
  const activeIndex = crumbs.findIndex(c => c.value === value)
  const handleDisabled = (crumb, index) => {
    if (disableClicks) {
      return true
    }
    if (activeIndex > index) {
      return false
    }
    if (crumb.requires !== null) {
      return !crumb.requires
    }
    return false
  }

  return (
    <Container>
      {crumbs?.length &&
        crumbs.map((crumb, index) => (
          <Crumb
            key={`crumb-${crumb.value}`}
            active={index <= activeIndex}
            value={crumb.value}
            label={crumb.label}
            withArrow={index > 0 && true}
            disabled={handleDisabled(crumb, index)}
            onClick={() => {
              if (disableClicks) {
                return
              }
              if (activeIndex > index) {
                onClick(crumb)
              } else if (crumb.requires || crumb.requires === null) {
                onClick(crumb)
              }
            }}
          />
        ))}
    </Container>
  )
}

const CrumbContainer = styled(Flex)`
  display: flex;
  align-items: center;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  margin-right: 8px;
  color: ${props => props.theme.colors.dark};

  .icon {
    transform: rotate(-90deg);
    margin-right: 8px;

    path {
      ${props =>
        !props.active &&
        `
        stroke: ${props.theme.colors.brownGrey};
      `}
    }
  }

  ${props =>
    !props.active &&
    `
    color: ${props.theme.colors.brownGrey};
  `}
`

const Crumb = ({ value, label, onClick, active, withArrow, disabled }) => {
  return (
    <CrumbContainer active={active} onClick={onClick} disabled={disabled}>
      {withArrow && <OpenArrow className="icon" />}
      {label || value}
    </CrumbContainer>
  )
}

export default BreadCrumbs

// export const useCrumbs = (initialCrumbs, options) => {
//   const [crumbsState, setCrumbsState] = useState({
//     crumbs: initialCrumbs,
//     activeIndex: 0,
//   })

//   const onClick = crumb => {
//     const index = crumbs.indexOf(crumb)
//     setCrumbsState({
//       ...crumbsState,
//       activeIndex: index,
//     })
//   }

//   return { crumbsState, onClick, setIndex }
// }
