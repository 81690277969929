import React, { useEffect, useState } from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"

import { Text } from "theme-ui"
import {
  CheckoutStep,
  StepTitle,
  StepBody,
  NextButton,
  ValidationNotification,
} from "../checkout-step"
import EmailSection from "./email-section"
import { AddressInput } from "../../new-ui/address-section"
import LoadingSpinner from "../../ui/LoadingSpinner"
import {
  trackCheckoutStepViewed,
  trackCheckoutStepCompleted,
} from "../../../services/analytics"
import { useAccountContext } from "../../../context/AccountContext"
import useDrawerHook from "../../../hooks/useDrawerHook"
import LoginModal from "../../new-ui/modal/login-modal"

const ShippingStep = (props) => {
  const [newsletterOptIn, setNewsletterOptIn] = useState(false)
  const { t } = useTranslation()

  const { drawerContent, setDrawerContent, setDrawerVisible } = useDrawerHook({
    config: { asModal: true, sx: { ".content": { backgroundColor: "white" } } },
  })

  const { loggedIn } = useAccountContext()

  const {
    isProcessing,
    cartAddress,
    canEditCountry,
    cart,
    validationErrors,
    onEdit,
    onSubmit,
  } = props

  useEffect(() => {
    trackCheckoutStepViewed(cart, 1)

    setDrawerContent(
      <LoginModal
        afterSubmit={() => {
          setDrawerVisible(false)
        }}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const AddressSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("invalid_email"))
      .required(t("required"))
      .nullable(),
    first_name: Yup.string().required(t("required")).nullable(),
    last_name: Yup.string().required(t("required")).nullable(),
    address_1: Yup.string().required(t("required")).nullable(),
    address_2: Yup.string().nullable(),
    city: Yup.string().required(t("required")).nullable(),
    province: Yup.string().nullable(),
    postal_code: Yup.string().required(t("required")).nullable(),
    country_code: Yup.string().required(t("required")).nullable(),
    phone: Yup.string().required(t("required")).nullable(),
  })

  const isComplete =
    cartAddress &&
    !!cartAddress.first_name &&
    !!cartAddress.last_name &&
    !!cartAddress.address_1 &&
    !!cartAddress.postal_code &&
    !!cartAddress.country_code &&
    !!cartAddress.phone

  const handleSubmit = (data) => {
    if (!cartAddress) {
      if (onEdit) {
        onEdit()
      }
    } else {
      trackCheckoutStepCompleted(cart, 1)
      if (onSubmit) {
        const address = {
          ...data,
        }

        delete address.email
        onSubmit(address, data.email)
      }
    }
  }

  const handleNewsletterOptIn = () => {
    setNewsletterOptIn((state) => !state)
  }

  return (
    <CheckoutStep saved={isComplete} id="shipping">
      <StepTitle
        sx={{ justifyContent: "space-between", alignItems: "baseline" }}
      >
        <Text>{t("your_info")}</Text>
        {!loggedIn && (
          <Text
            sx={{
              textDecoration: "underline",
              fontWeight: "bold",
              fontSize: "13px",
              cursor: "pointer",
            }}
            onClick={() => setDrawerVisible(true)}
          >
            {t("login_express")}
          </Text>
        )}
      </StepTitle>

      {!!validationErrors.length && (
        <ValidationNotification>
          {t("please_correct_fields")}
        </ValidationNotification>
      )}

      <StepBody>
        <Formik
          initialValues={{
            email: cart.email || "",
            address_1: cartAddress.address_1 || "",
            address_2: cartAddress.address_2 || "",
            city: cartAddress.city || "",
            postal_code: cartAddress.postal_code || "",
            province: cartAddress.province || "",
            country_code: cartAddress.country_code || "",
            phone: cartAddress.phone || "",
            first_name: cartAddress.first_name || "",
            last_name: cartAddress.last_name || "",
          }}
          onSubmit={(values) => handleSubmit(values)}
          validationSchema={AddressSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <EmailSection
                formik={formik}
                loggedIn={loggedIn}
                newsletterOptIn={newsletterOptIn}
                onNewsletterOptIn={handleNewsletterOptIn}
              />
              <StepTitle my={"1.5rem"}>{t("delivery_address")}</StepTitle>
              <AddressInput
                formik={formik}
                canEditCountry={canEditCountry}
                countryCode={cart.countryCode}
              />
              <NextButton type="submit">{t("next")}</NextButton>
            </form>
          )}
        </Formik>
      </StepBody>
      {isProcessing && <LoadingSpinner bg={"#f7f6f5ab"} />}
      {drawerContent}
    </CheckoutStep>
  )
}

export default ShippingStep
